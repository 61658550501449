import { CommonServices } from '@/services/CommonServices';
import * as OM from '@/model';
import { baseUrl } from '@/config';


class _DoodleServices {

    CreateLead(): Promise<OM.DoodleLead> {
        return CommonServices.get<OM.DoodleLead>(baseUrl + 'Doodle/CreateLead');
    }
        
    UpdateLead(item: OM.DoodleLead): Promise<OM.DoodleLead> {
        return CommonServices.post<OM.DoodleLead>(baseUrl + 'Doodle/UpdateLead', item);
    }
    
    RemoveBg(vm: OM.DoodleLeadImageVm): Promise<string> {
        return CommonServices.uploadFileToUrl<string>(baseUrl + 'Doodle/RemoveBg', vm);
    }

    SaveFinalImage(vm: OM.DoodleLeadImageVm): Promise<OM.DoodleLead> {
        return CommonServices.uploadFileToUrl<OM.DoodleLead>(baseUrl + 'Doodle/SaveFinalImage', vm);
    }

    SendMail(lead: OM.DoodleLead){
        return CommonServices.post<void>(baseUrl + 'Doodle/SendMail', lead);
    }
        
}
export var DoodleServices = new _DoodleServices();