













import { Component, Vue } from 'vue-property-decorator';
import { DoodleServices } from '@/services/DoodleServices';
import { StorageServices } from '@/services/StorageServices';
import { hasResearch } from '@/config';
import store from '@/store';
import * as OM from '@/model';

@Component
export default class Start extends Vue {

    next(){
        this.$router.push('welcome')
    }

}

