






























import { Component, Vue, Watch } from 'vue-property-decorator';
import { DoodleServices } from '@/services/DoodleServices';
import { StorageServices } from '../services/StorageServices';
import store from '@/store';
import * as OM from '@/model';
import Konva from 'konva';
import FileInput from '@/components/fileInput.vue';
import html2canvas from 'html2canvas';
import { gcd, urltoFile } from '../utils';

@Component({
    components: {
        FileInput
    }
})
export default class Gioco_Surf extends Vue {

    hasDownloaded: boolean = false;

    selectedImage: File = null;
    showUploader: boolean = true;
    showBounding: boolean = true;

    configFinal: Konva.ContainerConfig = {
        width: window.innerWidth < 1200 ? window.innerWidth : 1200,
        height: window.innerHeight < 750 ? window.innerHeight : 750,
    };
    configBackGround: Konva.ImageConfig = null;
    finalStage: Konva.Stage = null;

    imageLayer: Konva.Layer = null;
    boundingImageLayer: Konva.Layer = null;
    boundingImageCameraLayer: Konva.Layer = null;
    
    backgroundImageConfig: Konva.ImageConfig = null;
    boundingImageConfig: Konva.ImageConfig = null;
    boundingImageCameraConfig: Konva.ImageConfig = null;

    userImage: any = null;

    canvasRect: any = null;

    leftBound: number = 0;
    rightBound: number = 0;
    topBound: number = 0;
    bottomBound: number = 0;

    created(){
        // var ratioHeight = 1920 / 1200;
        var ratioWidth = 1200 / 1920;
        var width, height, top;
        width = window.innerWidth < 1200 ? window.innerWidth : 1200;
        height = width * ratioWidth;

        this.configFinal.width = width;
        this.configFinal.height = height;

        var image = new window.Image();
        image.src = "/img/seaside_bg.jpg";
        image.onload = () => {
            this.backgroundImageConfig = {
                image: image,
                width: width,
                height: height,
            }
            this.finalStage = (<any>this.$refs.finalStage).getStage();
            this.canvasRect = (<any>this.$refs.finalStage).$el.getBoundingClientRect();
            this.imageLayer = (<any>this.$refs.imageLayer).getStage();
            this.boundingImageLayer = (<any>this.$refs.boundingImageLayer).getStage();
            this.boundingImageCameraLayer = (<any>this.$refs.boundingImageCameraLayer).getStage();

            this.leftBound = this.canvasRect.left + this.canvasRect.width * 0.35;
            this.rightBound = this.leftBound + this.canvasRect.width * 0.28;
            this.topBound = this.canvasRect.top + (this.rightBound - this.leftBound) * ratioWidth;
            this.bottomBound = this.topBound + this.canvasRect.height * 0.635;

            // let tester = <any>this.$refs.tester;
            // tester.style.top = this.topBound + "px";
            // tester.style.left = this.leftBound + "px";
            // tester.style.bottom = this.bottomBound + "px";
            // tester.style.right = this.rightBound + "px";
            // tester.style.width = (this.rightBound - this.leftBound ) + "px";
            // tester.style.height = (this.bottomBound - this.topBound ) + "px";
        };
        
        var boundingImage = new window.Image();
        boundingImage.src = "/img/seaside_bg_bounding.png";
        boundingImage.onload = () => {
            this.boundingImageConfig = {
                image: boundingImage,
                width: width,
                height: height,
                listening: false
            }
        }
        
        var boundingImageCamera = new window.Image();
        boundingImageCamera.src = "/img/seaside_bg_bounding_camera.png";
        boundingImageCamera.onload = () => {
            this.boundingImageCameraConfig = {
                image: boundingImageCamera,
                width: width,
                height: height,
                listening: false
            }
        }
    }

    @Watch('selectedImage')
    onSelectedImageChange(next, prev){
        DoodleServices.RemoveBg({
            lead: store.state.lead,
            image: next,
        })
        .then(x => {
            this.showUploader = false;
            this.addUserImageFunction(x);
        })
        // this.showUploader = false;
        // this.addUserImageFunction('/img/test.png');
    }

    addUserImageFunction(imgSrc: string){
        let drawImage = (imageObj, width: number, height: number) => {
            var layer = new Konva.Layer({
                width: 100,
                height: 50
            });
            this.userImage = new Konva.Image({
                image: imageObj,
                x: this.imageLayer.width() / 2 - width / 2,
                y: this.imageLayer.height() / 2 - height / 2,
                width: width,
                height: height,
                listening: true,
                draggable: true,
            });
            this.userImage.on('dragmove', (ev) => {
                let leftX, rightX;
                let topY, bottomY;

                leftX = ev.target.x() + ev.target.width() / 2 + this.canvasRect.left;
                topY = ev.target.y() + ev.target.height() / 2 + this.canvasRect.top;
                rightX = leftX// + ev.target.width();
                bottomY = topY// + ev.target.height();

                if(leftX < this.leftBound) ev.target.setAttr('x', this.leftBound - this.canvasRect.left - ev.target.width() / 2);
                else if(rightX > this.rightBound) ev.target.setAttr('x', this.rightBound - this.canvasRect.left - ev.target.width() / 2);

                if(topY < this.topBound) ev.target.setAttr('y', this.topBound - this.canvasRect.top - ev.target.height() / 2);
                else if(bottomY > this.bottomBound) ev.target.setAttr('y', this.bottomBound - this.canvasRect.top - ev.target.height() / 2);

                return false;
            })
            // add cursor styling
            this.userImage.on('mouseover', function () {
                document.body.style.cursor = 'pointer';
            });
            this.userImage.on('mouseout', function () {
                document.body.style.cursor = 'default';
            });
            this.imageLayer.add(this.userImage);
        }
        var imageObj = new Image();
        let ctx = this;
        imageObj.onload = function () {
            let img: any = this;
            let ratioHeight = img.width / img.height;
            let ratioWidth = img.height / img.width;
            let finalHeight = 0;
            let finalWidth = 0;
            if(img.width > img.height){
                if(img.width > ctx.rightBound - ctx.leftBound)
                    finalWidth = (ctx.rightBound - ctx.leftBound) * 0.6;
                else
                    finalWidth = img.width;
                finalHeight = finalWidth * ratioWidth;
            } else {
                if(img.height > ctx.bottomBound - ctx.topBound)
                    finalHeight = (ctx.bottomBound - ctx.topBound) * 0.6;
                else
                    finalHeight = img.height;
                finalWidth = finalHeight * ratioHeight;
            }
            drawImage(img, finalWidth, finalHeight);
        };
        imageObj.src = imgSrc;
    }

    resize(howMuch){
        this.userImage.width(this.userImage.getWidth() + this.userImage.getWidth() * howMuch / 100);
        this.userImage.height(this.userImage.getHeight() + this.userImage.getHeight() * howMuch / 100);
    }

    scale(howMuch){
        this.userImage.scale({
            x: this.userImage.getAbsoluteScale().x + howMuch,
            y: this.userImage.getAbsoluteScale().y + howMuch
        });
    }
    
    downloadImage(){
        function downloadURI(uri, name) {
            var link = document.createElement('a');
            link.download = name;
            link.href = uri;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        this.showBounding = false;
        setTimeout(() => {
            var dataURL = this.finalStage.toDataURL({ pixelRatio: 3 });
            downloadURI(dataURL, 'stage.png');
            setTimeout(() => this.showBounding = true, 100);
        })            
        this.hasDownloaded = true;       
    }

    next(){
        if(!this.hasDownloaded)
            return;
            
        this.showBounding = false;
        setTimeout(() => {
            var dataURL = this.finalStage.toDataURL({ pixelRatio: 3 });
            urltoFile(dataURL, 'final.png', 'image/png')
            .then(x => {
                DoodleServices.SaveFinalImage({
                    lead: store.state.lead,
                    image: x,
                })
                .then(x => {
                    store.state.lead = x;
                    StorageServices.setlead(store.state.lead);
                    this.showUploader = false;
                    this.$router.push('Raccolta_dati');
                })
            })
        })
    }

}

