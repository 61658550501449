









import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';

@Component
export default class App extends Vue {

    created(){
        // store.state.prevFunction = () => {
        //     this.$router.back();
        // }
    }

    get loading(){
        return store.state.showSpinner > 0;
    }

    // fullscreen(){
    //     let pippo: any = document;
    //     if (!pippo.fullscreenElement &&    // alternative standard method
    //         !pippo.mozFullScreenElement && !pippo.webkitFullscreenElement && !pippo.msFullscreenElement) {  // current working methods
    //         if (pippo.documentElement.requestFullscreen) {
    //             pippo.documentElement.requestFullscreen();
    //         } else if (pippo.documentElement.msRequestFullscreen) {
    //             pippo.documentElement.msRequestFullscreen();
    //         } else if (pippo.documentElement.mozRequestFullScreen) {
    //             pippo.documentElement.mozRequestFullScreen();
    //         } else if (pippo.documentElement.webkitRequestFullscreen) {
    //             pippo.documentElement.webkitRequestFullscreen((<any>Element).ALLOW_KEYBOARD_INPUT);
    //         }
    //     } else {
    //         if (pippo.exitFullscreen) {
    //             pippo.exitFullscreen();
    //         } else if (pippo.msExitFullscreen) {
    //             pippo.msExitFullscreen();
    //         } else if (pippo.mozCancelFullScreen) {
    //             pippo.mozCancelFullScreen();
    //         } else if (pippo.webkitExitFullscreen) {
    //             pippo.webkitExitFullscreen();
    //         }
    //     }
    // }

}
