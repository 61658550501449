



















import { Component, Vue } from 'vue-property-decorator';
import { DoodleServices } from '@/services/DoodleServices';
import { StorageServices } from '../services/StorageServices';
import store from '@/store';
import * as OM from '@/model';

@Component
export default class Success extends Vue {

    ris: number = 0;

    created() {
        this.ris = parseInt(this.$route.params.resultCount);
    }

    openGame() {
        this.$router.push('/Gioco_1');
    }

}

