


































import { Component, Vue } from 'vue-property-decorator';
import { DoodleServices } from '@/services/DoodleServices';
import { StorageServices } from '../services/StorageServices';
import store from '@/store';
import * as OM from '@/model';

@Component
export default class Gioco_2 extends Vue {
    created(){

        console.log(store.state.lead);
    }

    setAndGo(to){
        store.state.lead.risposteGioco.sfondo = to;
        setTimeout(() => {
            if(to == 'surf') {
                this.$router.push('Gioco_Surf')
            } else if(to == 'meditation') {
                this.$router.push('Gioco_Meditation')
            } else if(to == 'roadtrip') {
                this.$router.push('Gioco_Roadtrip')
            } else if(to == 'seaside') {
                this.$router.push('Gioco_Seaside')
            }
        }, 400);
    }

}

