






























import { Component, Vue } from 'vue-property-decorator';
import { DoodleServices } from '@/services/DoodleServices';
import { StorageServices } from '../services/StorageServices';
import store from '@/store';
import * as OM from '@/model';

@Component
export default class Domanda_3 extends Vue {

    setValue(value: string) {
        store.state.lead.risposteDoodle.segmentoSigarette = value;

        setTimeout(() => {
            DoodleServices.UpdateLead(store.state.lead)
            .then(x => {
                store.state.lead = x;
                StorageServices.setlead(store.state.lead);
                this.$router.push('Domanda_4');
            })
        }, 400);
    }

}

