import { uuidv4 } from './utils'

class BaseEntity
{
    identifier: string;
    createdOn: string;
    modifiedOn: string;
    disabledOn: string;
    disabled: boolean;
    constructor(){
        this.identifier = uuidv4();
        this.createdOn = new Date().toISOString();
        this.modifiedOn = new Date().toISOString();
        this.disabled = false;
        this.disabledOn = "";
    }
}

export class DoodleLead extends BaseEntity
{
    name: string;
    surname: string;
    email: string;
    punteggio: number;
    risposteDoodle: RisposteDoodle;
    risposteGioco: RisposteGioco;

    constructor(){
        super();
        this.name = "";
        this.surname = "";
        this.email = "";
        this.punteggio = 0;
        this.risposteDoodle = new RisposteDoodle();
        this.risposteGioco = new RisposteGioco();
    }
}

export class RisposteDoodle
{
    recordSom: string;
    posizioneRmc: string;
    segmentoSigarette: string;
    ultimaReferenza: string;
    categoriaFct: string;
    quantiPaesi: string;
    temaLimitedEdition: string;
    progettiSpecialiDueRegioni: string;
    constructor(){
        this.recordSom = "";
        this.posizioneRmc = "";
        this.segmentoSigarette = "";
        this.ultimaReferenza = "";
        this.categoriaFct = "";
        this.quantiPaesi = "";
        this.temaLimitedEdition = "";
        this.progettiSpecialiDueRegioni = "";
    }
}

export class RisposteGioco
{
    sfondo: string;
    constructor(){
        this.sfondo = "";
    }
}

export class LoginVm
{
    password: string;
}

export class LocaleData {
    citta: string;
    locale: string;
    tablet: string;
}

export class DoodleLeadImageVm
{
    lead: DoodleLead;
    image: File;
}