import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.min.css';
import '@/css/style.css';

document.addEventListener("deviceready", function () {
    if(Keyboard && Keyboard.hideFormAccessoryBar){
        Keyboard.hideFormAccessoryBar(false);
    }
    codePushUpdateFunction();
});
document.addEventListener("resume", function () {
    codePushUpdateFunction();
});
function codePushUpdateFunction(){
    codePush.sync(function(status) {
        switch (status) {
            case SyncStatus.DOWNLOADING_PACKAGE:
                break;
            case SyncStatus.INSTALLING_UPDATE:
                break;
        }
    }, {
        updateDialog: {
            appendReleaseDescription: false,
            descriptionPrefix: "",
            optionalUpdateMessage: "E' disponibile una nuova versione dell'app, premi Aggiorna per proseguire!",
            optionalInstallButtonLabel: "Aggiorna",
            optionalIgnoreButtonLabel: "Rimanda",
            updateTitle: "Aggiornamento disponibile"
        },
        installMode: InstallMode.IMMEDIATE
     });
}

window.document.addEventListener('deviceready', function(){
    console.log("ready");

    (<any>window.screen).orientation.lock('landscape');
    //AndroidFullScreen.immersiveMode(() => {console.log("immersed")}, (err) => {console.log(err)});
}, false)

Vue.config.productionTip = false

import VueKonva from 'vue-konva';
Vue.use(VueKonva);

import MiniSpinner from '@/components/miniSpinner.vue';
Vue.component('miniSpinner', MiniSpinner);


import { CommonServices } from '@/services/CommonServices'
CommonServices.showSpinnerCb = () => {
    store.state.showSpinner++;
}
CommonServices.hideSpinnerCb = () => {
    store.state.showSpinner--;
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
