





















import { Component, Vue } from 'vue-property-decorator';
import { DoodleServices } from '@/services/DoodleServices';
import { StorageServices } from '../services/StorageServices';
import store from '@/store';
import * as OM from '@/model';

@Component
export default class Welcome extends Vue {

    next(){
        store.state.lead = new OM.DoodleLead();
        DoodleServices.CreateLead()
        .then(x => {
            store.state.lead = x;
            StorageServices.setlead(x);
            this.$router.push('Domanda_1')
        })
    }

}

