



























import { Component, Vue } from 'vue-property-decorator';
import { DoodleServices } from '@/services/DoodleServices';
import { StorageServices } from '../services/StorageServices';
import store from '@/store';
import * as OM from '@/model';

@Component
export default class Domanda_8 extends Vue {

    setValue(value: string) {
        store.state.lead.risposteDoodle.progettiSpecialiDueRegioni = value;

        setTimeout(() => {
            store.state.lead.punteggio = 0; 
            if(store.state.lead.risposteDoodle.recordSom == '13,7 %')
                store.state.lead.punteggio++;
            if(store.state.lead.risposteDoodle.posizioneRmc == '2^')
                store.state.lead.punteggio++;
            if(store.state.lead.risposteDoodle.segmentoSigarette == 'Low Tar')
                store.state.lead.punteggio++;
            if(store.state.lead.risposteDoodle.ultimaReferenza == 'Caster')
                store.state.lead.punteggio++;
            if(store.state.lead.risposteDoodle.categoriaFct == '5^')
                store.state.lead.punteggio++;
            if(store.state.lead.risposteDoodle.quantiPaesi == 'Più di 140')
                store.state.lead.punteggio++;
            if(store.state.lead.risposteDoodle.temaLimitedEdition == 'Musica')
                store.state.lead.punteggio++;
            if(store.state.lead.risposteDoodle.progettiSpecialiDueRegioni == 'Lazio, Sicilia')
                store.state.lead.punteggio++;
            
            DoodleServices.UpdateLead(store.state.lead)
            .then(x => {
                store.state.lead = x;
                StorageServices.setlead(store.state.lead);
                if(store.state.lead.punteggio >= 5)
                    this.$router.push('Success/' + store.state.lead.punteggio);
                else
                    this.$router.push('Failed/' + store.state.lead.punteggio);
            })
        }, 400);
    }

}

