import Axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';

class _CommonServices {
    
    showSpinnerCb = () => {};
    hideSpinnerCb = () => {};
    unauthorizedCb = () => {};

    get<T = any>(url: string, params?: any, _showSpinner = true): Promise<T> {
        let req: AxiosRequestConfig = {
            params
        };
        if(_showSpinner)
            this.showSpinnerCb();
        let prom = new Promise<T>((resolve, reject) => {
            Axios.get<T>(url, req)
            .then(x => {
                resolve(x.data);
            }).catch( err => {
                reject(err);
            })
            .finally(() => {
                if(_showSpinner)
                    this.hideSpinnerCb();
            });
        });
        return prom;
    }
    post<T = any>(url: string, data?: any, config?: AxiosRequestConfig, _showSpinner = true): Promise<T> {
        if(_showSpinner)
            this.showSpinnerCb()
        let prom = new Promise<T>((resolve, reject) => {
            Axios.post(url, data, config).then(x => {
                resolve(x.data);
            }).catch( err => {
                reject(err);
            })
            .finally(() => {
                if(_showSpinner)
                    this.hideSpinnerCb();
            });
        });
        return prom;
    }
    private addFile(form, fileUri, key) {
        fileUri = encodeURI(fileUri);
        console.log("adding file " + fileUri);
        console.log("with key " + key);
        let self = this;
        let promise = new Promise<void>((resolve, reject) => {
            (<any>window).resolveLocalFileSystemURL(fileUri, function (fileEntry) {
                fileEntry.file(function (file) {
                    var reader = new FileReader();
                    reader.onloadend = function (fileReadResult: any) {
                        var data = new Uint8Array(fileReadResult.target.result);
                        var blob = self.createBlob(data, file.type || self.getMimeType(file.name));
                        form.append(key, blob, file.name);
                        resolve();
                    };
                    
                    reader.onerror = function (fileReadResult) {
                        reject(fileReadResult);
                    };
                    reader.readAsArrayBuffer(file);
                });
            });
            
        })
        return promise;
    }
    private createBlob(data, type) {
        var r;
        r = new window.Blob([data], {type: type});
        return r;
    }
    private mimetypes = {
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'png': 'image/png'
    };
    private getMimeType(fileName) {
        var extension = fileName.split('.').pop();
        return this.mimetypes.hasOwnProperty(extension) ? this.mimetypes[extension] : undefined;
    }
    private async convertModelToFormData(data = {}, form = null, namespace = '') {
        let files = {};
        let model = {};
        for (let propertyName in data) {
            if (data.hasOwnProperty(propertyName) && data[propertyName] instanceof File) {
                files[propertyName] = data[propertyName]
            } else {
                model[propertyName] = data[propertyName]
            }
        }
        let formData = form || new FormData();
        for (let propertyName in model) {
            if (!model.hasOwnProperty(propertyName) || !model[propertyName]) 
                continue;
            
            let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
            if (model[propertyName] instanceof Date){
                formData.append(formKey, model[propertyName].toISOString());
            } else if (model[propertyName] instanceof File) {
                formData.append(formKey, model[propertyName]);
            } else if (model[propertyName] instanceof Array) {
                model[propertyName].forEach(async (element, index) => {
                    let tempFormKey = `${formKey}[${index}]`;
                    if(element instanceof File){
                        tempFormKey = tempFormKey.substring(0, tempFormKey.length - 3);
                        formData.append(tempFormKey, element);
                    } else if(typeof element == "string" && window.cordova){
                        if(element.startsWith("file:///")){
                            tempFormKey = tempFormKey.substring(0, tempFormKey.length - 3);
                            await this.addFile(formData, element, tempFormKey);
                        }
                    } else if (typeof element === 'object') {
                        this.convertModelToFormData(element, formData, tempFormKey);
                    } else {
                        formData.append(tempFormKey, element.toString());
                    } 
                });
            } else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)){
                this.convertModelToFormData(model[propertyName], formData, formKey);
            } else {
                if(typeof model[propertyName] == "string" && window.cordova){
                    if(model[propertyName].startsWith("file:///")){
                        await this.addFile(formData, model[propertyName], formKey);
                    } else {
                        formData.append(formKey, model[propertyName].toString());
                    }
                } else {
                    formData.append(formKey, model[propertyName].toString());
                }
            }
        }
        for (let propertyName in files) {
            if (files.hasOwnProperty(propertyName)) {
                formData.append(propertyName, files[propertyName]);
            }
        }
        return formData;
    }
    async uploadFileToUrl<T = any>(url: string, params: { [key: string]: any }, 
        onUploadProgress?: (progress: number) => void, _showSpinner = true): Promise<T> {
        var data = new FormData();
        if (params) {
            data = await this.convertModelToFormData(params);
        }
        var config = {
            onUploadProgress: function (ev: any) {
                if(typeof onUploadProgress == 'function')
                onUploadProgress((100 * ev.loaded) / ev.total);
            }
        };
        if(_showSpinner)
        this.showSpinnerCb()
        let prom = new Promise<T>((resolve, reject) => {
            Axios.post<T>(url, data, config).then(x => {
                resolve(x.data);
            })
            .catch( err => {
                reject(err);
            })
            .finally(() => {
                if(_showSpinner)
                this.hideSpinnerCb();
            });
        });
        return prom;
    }
    setAuthToken(token: string) {
        Axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        window.localStorage.setItem('__authtoken', token);
    }
    destroyToken() {
        Axios.defaults.headers.common['Authorization'] = "";
        window.localStorage.removeItem('__authtoken');
    }

    constructor() {
        let token = window.localStorage.getItem('__authtoken');
        if (token){
            this.setAuthToken(token);
        }
        Axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if(!error)
                return Promise.reject();

            if(!error.response)
                return Promise.reject(error);

            if (error.response.status == 401) {
                this.unauthorizedCb();
            } else if (error.response.status[0] == 5) {
                console.log("500");
                console.log(error);
            } else {
                if(error.response.data){
                    if(error.response.data.message)
                        return Promise.reject(error.response.data.message);
                    else
                        return Promise.reject(error.response.data);
                } else {
                    return Promise.reject(error);
                }
            }
        });
    }
}

export let CommonServices = new _CommonServices();