






























import { Component, Vue } from 'vue-property-decorator';
import { DoodleServices } from '@/services/DoodleServices';
import { StorageServices } from '../services/StorageServices';
import store from '@/store';
import * as OM from '@/model';

@Component
export default class Gioco_1 extends Vue {

    next() {
        this.$router.push('/Gioco_2');
    }

}

