import * as OM from '@/model';
import store from '@/store';

class _StorageServices  {
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }
    
    getlead() {
        return this.readFromLocalStorage<OM.DoodleLead>('doodleLead');
    }
    setlead(lead: OM.DoodleLead) {
        window.localStorage.setItem('doodleLead', JSON.stringify(lead));
    }

}

export let StorageServices = new _StorageServices();