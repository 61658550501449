




import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class FileInput extends Vue {

    @Prop() accept: string;

    onFileSelect(ev){
        let file = ev.target.files[0];
        this.$emit('input', file);
    }
}

