




















import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';
import { DoodleServices } from '@/services/DoodleServices';
import { StorageServices } from '@/services/StorageServices';
import * as OM from '@/model';
import Privacy from '../privacy.vue';

@Component({
    components: {
        Privacy
    }
})
export default class Email_Inviata extends Vue {

    showPrivacy: boolean = false;

    next(){
        this.$router.push('/');
    }

}

