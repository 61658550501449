import Vue from 'vue'
import Router from 'vue-router'
import Start from '@/views/Start.vue'
import Welcome from '@/views/Welcome.vue'
import Domanda_1 from '@/views/Domanda_1.vue'
import Domanda_2 from '@/views/Domanda_2.vue'
import Domanda_3 from '@/views/Domanda_3.vue'
import Domanda_4 from '@/views/Domanda_4.vue'
import Domanda_5 from '@/views/Domanda_5.vue'
import Domanda_6 from '@/views/Domanda_6.vue'
import Domanda_7 from '@/views/Domanda_7.vue'
import Domanda_8 from '@/views/Domanda_8.vue'
import Success from '@/views/Success.vue'
import Failed from '@/views/Failed.vue'
import Gioco_1 from '@/views/Gioco_1.vue'
import Gioco_2 from '@/views/Gioco_2.vue'
import Gioco_Meditation from '@/views/Gioco_Meditation.vue'
import Gioco_Roadtrip from '@/views/Gioco_Roadtrip.vue'
import Gioco_Seaside from '@/views/Gioco_Seaside.vue'
import Gioco_Surf from '@/views/Gioco_Surf.vue'
import RaccoltaDati from '@/views/RaccoltaDati.vue'
import Email_Inviata from '@/views/Email_Inviata.vue'

Vue.use(Router)

export default new Router({
	routes: [
		{
			path: '/',
			name: 'start',
			component: Start
		},
		{
			path: '/welcome',
			name: 'welcome',
			component: Welcome
        },
        {
			path: '/Domanda_1',
			name: 'domanda_1',
			component: Domanda_1
        },
        {
			path: '/Domanda_2',
			name: 'domanda_2',
			component: Domanda_2
        },
        {
			path: '/Domanda_3',
			name: 'domanda_3',
			component: Domanda_3
        },
        {
			path: '/Domanda_4',
			name: 'domanda_4',
			component: Domanda_4
        },
        {
			path: '/Domanda_5',
			name: 'domanda_5',
			component: Domanda_5
        },
        {
			path: '/Domanda_6',
			name: 'domanda_6',
			component: Domanda_6
        },
        {
			path: '/Domanda_7',
			name: 'domanda_7',
			component: Domanda_7
        },
        {
			path: '/Domanda_8',
			name: 'domanda_8',
			component: Domanda_8
        },
        {
			path: '/success/:resultCount',
			name: 'success',
			component: Success
        },
        {
			path: '/failed/:resultCount',
			name: 'failed',
			component: Failed
		},
        {
			path: '/Gioco_1',
			name: 'gioco_1',
			component: Gioco_1
        },
        {
			path: '/Gioco_2',
			name: 'gioco_2',
			component: Gioco_2
        },
        {
			path: '/Gioco_Meditation',
			name: 'gioco_Meditation',
			component: Gioco_Meditation
        },
        {
			path: '/Gioco_Roadtrip',
			name: 'gioco_Roadtrip',
			component: Gioco_Roadtrip
        },
        {
			path: '/Gioco_Seaside',
			name: 'gioco_Seaside',
			component: Gioco_Seaside
        },
        {
			path: '/Gioco_Surf',
			name: 'gioco_Surf',
			component: Gioco_Surf
        },
        {
			path: '/Raccolta_dati',
			name: 'raccolta_dati',
			component: RaccoltaDati
        },
        {
			path: '/Email_Inviata',
			name: 'email_Inviata',
			component: Email_Inviata
        },
	]
})
