import Vue from 'vue'
import Vuex from 'vuex'
import * as OM from '@/model'
import { StorageServices } from '@/services/StorageServices'

Vue.use(Vuex)

let state = {
    lead: new OM.DoodleLead(),
    showSpinner: 0
}

state.lead = StorageServices.getlead() || new OM.DoodleLead();

export default new Vuex.Store({
    state: state,
})