


























import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';
import { DoodleServices } from '@/services/DoodleServices';
import { StorageServices } from '@/services/StorageServices';
import * as OM from '@/model';
import Privacy from '../privacy.vue';

@Component({
    components: {
        Privacy
    }
})
export default class RaccoltaDati extends Vue {

    showPrivacy: boolean = false;

    get canSend(){
        return store.state.lead.name?.trim() && store.state.lead.surname?.trim() && store.state.lead.email?.trim();
    }

    next(){
        if(!this.canSend)
            return;

        store.state.lead.name = store.state.lead.name.trim();
        store.state.lead.surname = store.state.lead.surname.trim();
        store.state.lead.email = store.state.lead.email.trim();

        DoodleServices.UpdateLead(store.state.lead)
        .then(x => {
            store.state.lead = x;
            StorageServices.setlead(store.state.lead);
            DoodleServices.SendMail(store.state.lead)
            .then(x => {
                this.$router.push('Email_Inviata');
            })
        })
    }

}

