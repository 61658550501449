
































































































import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';
import { DoodleServices } from '@/services/DoodleServices';
import { StorageServices } from '@/services/StorageServices';
import * as OM from '@/model';

@Component
export default class Privacy extends Vue {

    created(){
    }
}

